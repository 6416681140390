/* .container {
	display: grid;
	grid-template-columns: 7fr 3fr;
} */

.myId {
  /* margin-right: 5rem; */
  border-radius: 5px;
  /* background: #c9d6ff;
  background: -webkit-linear-gradient(to right, #e2e2e2, #c9d6ff);
  background: linear-gradient(to right, #e2e2e2, #c9d6ff); */

  padding: 1rem;
  display: grid;
  justify-content: center;
  align-content: center;
}

.button-row {
  display: flex;
  justify-content: center;
  align-content: center;
}

.call-button {
  text-align: center;
  /* margin-top: 2rem; */
}

.call-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .video-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-content: center;
  margin-top: 10rem;
  margin-left: 10rem;
} */

.caller {
  text-align: center;
  color: #fff;
}

.button {
  background-color: #8d5ce0;
  color: white;
  padding: 0;
  height: 35px;
  border-radius: 0.2rem;
  /* margin: 10px; */
}

.button:hover {
  opacity: 0.7; /* reduce opacity to 70% when hovered over */
}

.circular-button {
  border-radius: 50%;
  background-color: #8d5ce0;
  color: white;
  height: 48px;
  width: 48px;
  padding: 0;
  margin: 10px;
}

.circular-button-call {
  background-color: #8d5ce0;
}

.circular-button-end-call {
  background-color: red;
}

.circular-button:hover {
  opacity: 0.7; /* reduce opacity to 70% when hovered over */
}

/* body {
  background: #4776e6;
  background: -webkit-linear-gradient(to right, #8e54e9, #4776e6);
  background: linear-gradient(to right, #8e54e9, #4776e6);
} */
